import React, { Component } from "react";
import db from "./../db.json";
import LocationHeader from "./LocationHeader";
import MainHeader from "./MainHeader";
import Slider from "./Slider";
import AboutUs from "./AboutUs";
import ProjectDetails from "./ProjectDetails";
import WhatWeDo from "./WhatWeDo";
import Projects from "./Projects";
import TestiMonials from "./Testimonials";
import Footer from "./Footer";
import BannerArea from "./BannerArea";
import Team from "./Team";
class FourzeroFourPage extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <>
        <LocationHeader data={db.locationHeader} />
        <MainHeader data={db.mainHeader} menus={db.Menus} />
        <BannerArea data={db["404Page"]} />

        <section id="main-container" className="main-container">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="error-page text-center">
                  <div className="error-code">
                    <h2>
                      <strong>404</strong>
                    </h2>
                  </div>
                  <div className="error-message">
                    <h3>Oops... Page Not Found!</h3>
                  </div>
                  <div className="error-body">
                    Try using the button below to go to main page of the site{" "}
                    <br />
                    <a href="/" className="btn btn-primary">
                      Back to Home Page
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* Content row */}
          </div>
          {/* Conatiner end */}
        </section>

        <Footer
          data={db.footers}
          header={db.locationHeader}
          services={db.services}
        />
      </>
    );
  }
}

export default FourzeroFourPage;
