import React, { Component } from "react";
class TestiMonials extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <>
        {/* Project area end */}
        <section className="content" id="testimonials">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <h3 className="column-title">{this.props.data.heading}</h3>
                <div id="testimonial-slide" className="testimonial-slide">
                  {this.props.data.reviews &&
                  this.props.data.reviews.length > 0 ? (
                    this.props.data.reviews.map((rew, index) => {
                      return (
                        <div key={index} className="item">
                          <div className="quote-item">
                            <span className="quote-text">{rew.review}</span>
                            <div className="quote-item-footer">
                              <img
                                loading="lazy"
                                className="testimonial-thumb"
                                src={require(`./../images/clients/${rew.image}`)}
                                alt="testimonial"
                              />
                              <div className="quote-item-info">
                                <h3 className="quote-author">
                                  {rew.authorname}
                                </h3>
                                <span className="quote-subtext">
                                  {rew.subtext}
                                </span>
                              </div>
                            </div>
                          </div>
                          {/* Quote item end */}
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>
                {/*/ Testimonial carousel end*/}
              </div>
              {/* Col end */}
              <div className="col-lg-8 mt-5 mt-lg-0">
                <h3 className="column-title">
                  {this.props.data.happyclientTitle}
                </h3>
                <div className="row all-clients">
                  {this.props.data.happyclients &&
                  this.props.data.happyclients.length > 0 ? (
                    this.props.data.happyclients.map((cli, index) => {
                      return (
                        <div key={index} className="col-sm-4 col-6">
                          <figure className="clients-logo">
                            <a href="#!">
                              <img
                                loading="lazy"
                                className="img-fluid"
                                style={{ filter: "unset" }}
                                src={require(`./../images/clients/${cli.image}`)}
                                alt="clients-logo"
                              />
                            </a>
                          </figure>
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>
                {/* Clients row end */}
              </div>
              {/* Col end */}
            </div>
            {/*/ Content row end */}
          </div>
          {/*/ Container end */}
        </section>
      </>
    );
  }
}

export default TestiMonials;
