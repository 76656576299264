import React, { Component } from "react";

class AboutUs extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <>
        {this.props.isAboutUsPage ? (
          <>
            <>
              <section id="main-container" className="main-container">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <h3 className="column-title">
                        {this.props.page.heading2}
                      </h3>
                      {this.props.page.paras &&
                      this.props.page.paras.length > 0 ? (
                        this.props.page.paras.map((data, index) => {
                          return (
                            <p key={index} style={{ textAlign: "justify" }}>
                              {data.description}
                            </p>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                    {/* Col end */}
                    <div className="col-lg-6 mt-5 mt-lg-0">
                      <div id="page-slider" className="page-slider small-bg">
                        {this.props.page.sliderImages &&
                        this.props.page.sliderImages.length > 0 ? (
                          this.props.page.sliderImages.map((data) => {
                            return (
                              <div
                                className="item"
                                style={{
                                  backgroundImage: `url(${require(`./../images/slider-pages/${data.image}`)})`,
                                }}
                              >
                                <div className="container">
                                  <div className="box-slider-content">
                                    <div className="box-slider-text">
                                      <h2 className="box-slide-title">
                                        {data.title}
                                      </h2>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </div>
                      {/* Page slider end*/}
                    </div>
                    {/* Col end */}
                  </div>
                  {/* Content row end */}
                </div>
                {/* Container end */}
              </section>
              {/* Main container end */}
            </>
          </>
        ) : (
          <>
            {/* Action end */}
            <section id="ts-features" className="ts-features">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="ts-intro">
                      <h2 className="into-title">About Us</h2>
                      <h3 className="into-sub-title">
                        {this.props.aboutus.topheading}
                      </h3>
                      <p style={{ textAlign: "justify" }}>
                        {this.props.aboutus.description}
                      </p>
                    </div>
                    {/* Intro box end */}
                    <div className="gap-20" />

                    {this.props.aboutus.into &&
                    this.props.aboutus.into.length > 0 ? (
                      this.props.aboutus.into.map((data, index) => {
                        return (
                          <div key={index} className="row">
                            {data.group && data.group.length > 0 ? (
                              data.group.map((row) => {
                                return (
                                  <div className="col-md-6">
                                    <div className="ts-service-box">
                                      <span className="ts-service-icon">
                                        {row.image ? (
                                          <img
                                            src={require(`./../images/aboutus/${row.image}`)}
                                          />
                                        ) : (
                                          <></>
                                        )}
                                        {/* <i className="fas fa-sliders-h" /> */}
                                      </span>
                                      <div className="ts-service-box-content">
                                        <h3 className="service-box-title">
                                          {row.title}
                                        </h3>
                                      </div>
                                    </div>
                                    {/* Service 2 end */}
                                  </div>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </div>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                  {/* Col end */}
                  <div className="col-lg-6 mt-4 mt-lg-0">
                    <h3 className="into-sub-title">Our Values</h3>
                    <p>{this.props.ourvalues.description}</p>
                    <div
                      className="accordion accordion-group"
                      id="our-values-accordion"
                    >
                      {this.props.ourvalues.values &&
                      this.props.ourvalues.values.length > 0 ? (
                        this.props.ourvalues.values.map((data, index) => {
                          return (
                            <div className="card">
                              <div
                                className="card-header p-0 bg-transparent"
                                id={`heading${index}`}
                              >
                                <h2 className="mb-0">
                                  <button
                                    className="btn btn-block text-left collapsed"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target={`#collapse${index}`}
                                    aria-expanded="false"
                                    aria-controls={`collapse${index}`}
                                  >
                                    {data.title}
                                  </button>
                                </h2>
                              </div>
                              <div
                                id={`collapse${index}`}
                                className="collapse"
                                aria-labelledby={`heading${index}`}
                                data-parent="#our-values-accordion"
                              >
                                <div className="card-body">
                                  {data.description}
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                    {/*/ Accordion end */}
                  </div>
                  {/* Col end */}
                </div>
                {/* Row end */}
              </div>
              {/* Container end */}
            </section>
            {/* Feature are end */}
          </>
        )}
      </>
    );
  }
}

export default AboutUs;
