import axios from "axios";
import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";

class QuoteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      service: "",
      location: "",
      fullName: "",
      email: "",
      contact: "",
      message: "",
      attachment: null,
      errors: {},
    };
  }

  handleInputChange = (e) => {
    const { name, value, files } = e.target;
    this.setState({
      [name]: name === "attachment" ? files[0] : value,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { service, fullName, email, contact, message } = this.state;
    const errors = {};

    if (!fullName) {
      errors.fullName = "Full Name is required.";
    }

    if (!email) {
      errors.email = "Email is required.";
    }

    if (!contact) {
      errors.contact = "Contact is required.";
    }

    if (!message) {
      errors.message = "Message is required.";
    }

    if (Object.keys(errors).length > 0) {
      toast.error("Please enter all values");
    } else {
      // Handle the form submission here, e.g., send data to a server.
      const formData = new FormData();
      formData.append("service", this.state.service);
      formData.append("location", this.state.location);
      formData.append("fullName", this.state.fullName);
      formData.append("email", this.state.email);
      formData.append("contact", this.state.contact);
      formData.append("message", this.state.message);
      formData.append("attachment", this.state.attachment);

      await axios
        .post(
          "https://constructopia-api.netlify.app/.netlify/functions/api/submit-quote",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((data) => {
          toast.success("Email sent successfully!", {
            position: toast.POSITION.TOP_RIGHT,
          });

          // Handle success or redirect as needed

          window.location.reload();
        })
        .catch((err) => {
          toast.error("Error sending email");
        });
    }
  };
  render() {
    const { service, location, fullName, email, contact, message, errors } =
      this.state;
    const servicesList = [
      "Drawings/Drafting (2D models)",
      "3D Modelling",
      "Budget & Costing/Estimate",
      "Steel Detailing / Bar Bending Schedule",
      "Planning / Programming",
      "SITE INSPECTIONS / PROJECT MANAGEMENT",
      "Construction Survey",
      "Other",
    ];

    return (
      <>
        {/* Modal */}
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Get a quote
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="service">Service *</label>
                    <select
                      className="form-control"
                      id="service"
                      name="service"
                      value={service}
                      onChange={this.handleInputChange}
                      required
                    >
                      <option value="" disabled>
                        Select a service
                      </option>
                      {servicesList.map((service, index) => (
                        <option key={index} value={service}>
                          {service}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="location">Location (Optional)</label>
                    <input
                      type="text"
                      className="form-control"
                      id="location"
                      name="location"
                      value={location}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="fullName">Full Name *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="fullName"
                      name="fullName"
                      value={fullName}
                      onChange={this.handleInputChange}
                    />
                    {errors.fullName && (
                      <div className="text-danger">{errors.fullName}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email *</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      value={email}
                      onChange={this.handleInputChange}
                    />
                    {errors.email && (
                      <div className="text-danger">{errors.email}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="contact">Contact *</label>
                    <input
                      type="tel"
                      className="form-control"
                      id="contact"
                      name="contact"
                      value={contact}
                      onChange={this.handleInputChange}
                    />
                    {errors.contact && (
                      <div className="text-danger">{errors.contact}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="message">Message *</label>
                    <textarea
                      className="form-control"
                      id="message"
                      name="message"
                      value={message}
                      onChange={this.handleInputChange}
                    ></textarea>
                    {errors.message && (
                      <div className="text-danger">{errors.message}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="attachment">Attachment (Optional)</label>
                    <input
                      type="file"
                      className="form-control-file"
                      id="attachment"
                      name="attachment"
                      onChange={this.handleInputChange}
                    />
                  </div>

                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </>
    );
  }
}

export default QuoteModal;
