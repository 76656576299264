import React, { Component } from "react";

class Faqs extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <>
        <>
          <section id="main-container" className="main-container">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  {this.props.data && this.props.data.length > 0 ? (
                    this.props.data.map((data, index) => {
                      return (
                        <>
                          {index !== 0 ? <div className="gap-40" /> : <></>}
                          <h3 className="border-title border-left mar-t0">
                            {data.title}
                          </h3>
                          <div
                            key={index}
                            className="accordion accordion-group "
                            id="construction-accordion"
                          >
                            {data.questions && data.questions.length > 0 ? (
                              data.questions.map((que, index) => {
                                return (
                                  <div key={index} className="card">
                                    <div
                                      className="card-header p-0 bg-transparent"
                                      id={`heading${data.id}${index}`}
                                    >
                                      <h2 className="mb-0">
                                        <button
                                          className="btn btn-block text-left collapsed"
                                          type="button"
                                          data-toggle="collapse"
                                          data-target={`#collapse${data.id}${index}`}
                                          aria-expanded="false"
                                          aria-controls={`collapse${data.id}${index}`}
                                        >
                                          {que.question}
                                        </button>
                                      </h2>
                                    </div>
                                    <div
                                      id={`collapse${data.id}${index}`}
                                      className="collapse"
                                      aria-labelledby={`heading${data.id}${index}`}
                                      data-parent="#construction-accordion"
                                    >
                                      <div className="card-body">
                                        {que.answer}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <></>
                  )}

                  {/*/ Accordion end */}
                </div>

                {/* Col end */}
              </div>
              {/* Content row end */}
            </div>
            {/* Container end */}
          </section>
          {/* Main container end */}
        </>
      </>
    );
  }
}

export default Faqs;
