import React, { Component } from "react";

class SoftwareWeUse extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <>
        {/* Project area end */}
        <section className="content solid-bg">
          <div className="container">
            <div className="row">
              {/* Col end */}
              <div className="col-lg-12 mt-5 mt-lg-0 text-center">
                <h3 className="column-title ">{this.props.data.heading}</h3>
                <div className="row all-clients">
                  {this.props.data.images &&
                  this.props.data.images.length > 0 ? (
                    this.props.data.images.map((cli, index) => {
                      return (
                        <div key={index} className="col-sm-4 col-6">
                          <figure className="clients-logo">
                            <a href="#!">
                              <img
                                loading="lazy"
                                className="img-fluid"
                                src={require(`./../images/softwares/${cli.image}`)}
                                style={{ filter: "unset" }}
                                alt="clients-logo"
                              />
                            </a>
                          </figure>
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>
                {/* Clients row end */}
              </div>
              {/* Col end */}
            </div>
            {/*/ Content row end */}
          </div>
          {/*/ Container end */}
        </section>
      </>
    );
  }
}

export default SoftwareWeUse;
