import React, { Component } from "react";
import db from "./../db.json";
import LocationHeader from "./LocationHeader";
import MainHeader from "./MainHeader";
import Slider from "./Slider";
import AboutUs from "./AboutUs";
import ProjectDetails from "./ProjectDetails";
import WhatWeDo from "./WhatWeDo";
import Projects from "./Projects";
import TestiMonials from "./Testimonials";
import Footer from "./Footer";
import BannerArea from "./BannerArea";
import Team from "./Team";
import Services from "./Services";
import ProjectSingle from "./ProjectSingle";

class ProjectSinglePage extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("id");
    // console.log(id);

    if (!id) {
      id = 1;
    }
    var service = db.projects.details.filter((data) => {
      return data.id === parseInt(id);
    });

    // console.log(service);
    this.setState({ selectedService: service.length > 0 ? service[0] : {} });
  }
  state = {
    selectedService: {},
  };

  render() {
    return (
      <>
        <LocationHeader data={db.locationHeader} />
        <MainHeader data={db.mainHeader} menus={db.Menus} />
        <BannerArea data={db.projectspage} />
        <ProjectSingle data={this.state.selectedService} />

        <Footer
          data={db.footers}
          header={db.locationHeader}
          services={db.services}
        />
      </>
    );
  }
}

export default ProjectSinglePage;
