import React, { Component } from "react";
import urls from "../Helpers/urls";
class Footer extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <>
        <footer id="footer" className="footer bg-overlay">
          <div className="footer-main">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-lg-4 col-md-6 footer-widget footer-about">
                  <h3 className="widget-title">About Us</h3>
                  <img
                    loading="lazy"
                    width="200px"
                    className="footer-logo"
                    src={require(`./../images/${this.props.data.footerlogo}`)}
                    alt="Constra"
                  />
                  <p>{this.props.data.description}</p>
                  <div className="footer-social">
                    <ul>
                      {this.props.header.facebookPageLink ? (
                        <li>
                          <a
                            href={this.props.header.facebookPageLink}
                            aria-label="Facebook"
                          >
                            <i className="fab fa-facebook-f" />
                          </a>
                        </li>
                      ) : (
                        <></>
                      )}

                      {this.props.header.twitterLink ? (
                        <li>
                          <a
                            href={this.props.header.twitterLink}
                            aria-label="Twitter"
                          >
                            <i className="fab fa-twitter" />
                          </a>
                        </li>
                      ) : (
                        <></>
                      )}

                      {this.props.header.instagramHandle ? (
                        <li>
                          <a
                            href={this.props.header.instagramHandle}
                            aria-label="Instagram"
                          >
                            <i className="fab fa-instagram" />
                          </a>
                        </li>
                      ) : (
                        <></>
                      )}
                      {this.props.header.linkedinPage ? (
                        <li>
                          <a
                            href={this.props.header.linkedinPage}
                            aria-label="linkedin"
                          >
                            <i className="fab fa-linkedin" />
                          </a>
                        </li>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </div>
                  {/* Footer social end */}
                </div>
                {/* Col end */}
                <div className="col-lg-4 col-md-6 footer-widget mt-5 mt-md-0">
                  <h3 className="widget-title">Working Hours</h3>
                  <div className="working-hours">
                    {this.props.data.workdesc}
                    <br />
                    <br /> Monday - Friday:{" "}
                    <span className="text-right">
                      {this.props.data.mondayToFriday}{" "}
                    </span>
                    <br /> Saturday:{" "}
                    <span className="text-right">
                      {this.props.data.saturday}
                    </span>
                    <br /> Sunday and holidays:{" "}
                    <span className="text-right">{this.props.data.sunday}</span>
                  </div>
                </div>
                {/* Col end */}
                <div className="col-lg-3 col-md-6 mt-5 mt-lg-0 footer-widget">
                  <h3 className="widget-title">Services</h3>
                  <ul className="list-arrow">
                    {this.props.services && this.props.services.length > 0 ? (
                      this.props.services.map((data, index) => {
                        return (
                          <li key={index}>
                            <a href={`/Services?id=` + data.id}>{data.title}</a>
                          </li>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </ul>
                </div>
                {/* Col end */}
              </div>
              {/* Row end */}
            </div>
            {/* Container end */}
          </div>
          {/* Footer main end */}
          <div className="copyright">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-6">
                  All Rights Reserved | © 2023 Constructopia Limited
                </div>
                <div className="col-md-6">
                  <div className="footer-menu text-center text-md-right">
                    <ul className="list-unstyled">
                      {this.props.data.footerMenus &&
                      this.props.data.footerMenus.length > 0 ? (
                        this.props.data.footerMenus.map((data, index) => {
                          return (
                            <li key={index}>
                              <a href={data.route}>{data.title}</a>
                            </li>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              {/* Row end */}
              <div
                id="back-to-top"
                data-spy="affix"
                data-offset-top={10}
                className="back-to-top position-fixed"
              >
                <button className="btn btn-primary" title="Back to Top">
                  <i className="fa fa-angle-double-up" />
                </button>
              </div>
            </div>
            {/* Container end */}
          </div>
          {/* Copyright end */}
        </footer>
        {/* Footer end */}
      </>
    );
  }
}

export default Footer;
