import React, { Component } from "react";
class LocationHeader extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <>
        <div id="top-bar" className="top-bar">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-8">
                <ul className="top-info text-center text-md-left">
                  <li>
                    <i className="fas fa-map-marker-alt" />{" "}
                    <p className="info-text">{this.props.data.address}</p>
                  </li>
                </ul>
              </div>
              {/*/ Top info end */}
              <div className="col-lg-4 col-md-4 top-social text-center text-md-right">
                <ul className="list-unstyled">
                  <li>
                    {this.props.data.facebookPageLink ? (
                      <a
                        title="Facebook"
                        href={this.props.data.facebookPageLink}
                      >
                        <span className="social-icon">
                          <i className="fab fa-facebook-f" />
                        </span>
                      </a>
                    ) : (
                      <></>
                    )}

                    {this.props.data.twitterLink ? (
                      <a title="Twitter" href={this.props.data.twitterLink}>
                        <span className="social-icon">
                          <i className="fab fa-twitter" />
                        </span>
                      </a>
                    ) : (
                      <></>
                    )}
                    {this.props.data.instagramHandle ? (
                      <a
                        title="Instagram"
                        href={this.props.data.instagramHandle}
                      >
                        <span className="social-icon">
                          <i className="fab fa-instagram" />
                        </span>
                      </a>
                    ) : (
                      <></>
                    )}

                    {this.props.data.linkedinPage ? (
                      <a title="Linkdin" href={this.props.data.linkedinPage}>
                        <span className="social-icon">
                          <i className="fab fa-linkedin" />
                        </span>
                      </a>
                    ) : (
                      <></>
                    )}
                  </li>
                </ul>
              </div>
              {/*/ Top social end */}
            </div>
            {/*/ Content row end */}
          </div>
          {/*/ Container end */}
        </div>
        {/*/ Topbar end */}
      </>
    );
  }
}

export default LocationHeader;
