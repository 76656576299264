import React, { Component } from "react";

class BannerArea extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <>
        <>
          {/*/ Header end */}
          <div
            id="banner-area"
            className="banner-area"
            style={{
              backgroundImage: `url(${require(`./../images/banner/${this.props.data.bannerimage}`)})`,
            }}
          >
            <div className="banner-text">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="banner-heading">
                      <h1 className="banner-title">
                        {this.props.data.heading1}
                      </h1>
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-center">
                          {this.props.data.path &&
                          this.props.data.path.length > 0 ? (
                            this.props.data.path.map((data, index) => {
                              return (
                                <li
                                  key={index}
                                  className={
                                    data.islast
                                      ? "breadcrumb-item active"
                                      : "breadcrumb-item"
                                  }
                                >
                                  <a href="#">{data.title}</a>
                                </li>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </ol>
                      </nav>
                    </div>
                  </div>
                  {/* Col end */}
                </div>
                {/* Row end */}
              </div>
              {/* Container end */}
            </div>
            {/* Banner text end */}
          </div>
          {/* Banner area end */}
        </>
      </>
    );
  }
}

export default BannerArea;
