import React, { Component } from "react";
import db from "./../db.json";
import LocationHeader from "./LocationHeader";
import MainHeader from "./MainHeader";
import Slider from "./Slider";
import AboutUs from "./AboutUs";
import ProjectDetails from "./ProjectDetails";
import WhatWeDo from "./WhatWeDo";
import Projects from "./Projects";
import TestiMonials from "./Testimonials";
import Footer from "./Footer";
import BannerArea from "./BannerArea";
import Faqs from "./Faqs";
import QuoteModal from "./QuoteModal";
class FaqPage extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <>
        <LocationHeader data={db.locationHeader} />
        <MainHeader data={db.mainHeader} menus={db.Menus} />
        <BannerArea data={db.faqsPage} />
        <Faqs data={db.faqsPage.sections} />
        <Footer
          data={db.footers}
          header={db.locationHeader}
          services={db.services}
        />
        <QuoteModal />
      </>
    );
  }
}

export default FaqPage;
