import React, { Component } from "react";

class Team extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <>
        <>
          <section id="ts-team" className="ts-team">
            <div className="container">
              <div className="row text-center">
                <div className="col-lg-12">
                  <h2 className="section-title">{this.props.data.heading1}</h2>
                  <h3 className="section-sub-title">
                    {this.props.data.heading2}
                  </h3>
                </div>
              </div>
              {/*/ Title row end */}
              <div className="row">
                <div className="col-lg-12 text-center">
                  <img
                    className="img-fluid"
                    src={require(`./../images/${this.props.data.image}`)}
                  />
                  <div id="team-slide" className="team-slide">
                    {this.props.data.members &&
                    this.props.data.members.length > 0 ? (
                      this.props.data.members.map((data, index) => {
                        return (
                          <div key={index} className="item">
                            <div className="ts-team-wrapper">
                              <div className="team-img-wrapper">
                                <img
                                  loading="lazy"
                                  className="w-100"
                                  src={require(`./../images/team/${data.image}`)}
                                  alt="team-img"
                                />
                              </div>
                              <div className="ts-team-content">
                                <h3 className="ts-name">{data.name}</h3>
                                <p className="ts-designation">
                                  {data.designation}
                                </p>
                                <p className="ts-description">
                                  {data.description}
                                </p>
                                <div className="team-social-icons">
                                  {data.fb ? (
                                    <a target="_blank" href={data.fb}>
                                      <i className="fab fa-facebook-f" />
                                    </a>
                                  ) : (
                                    <></>
                                  )}
                                  {data.twitter ? (
                                    <a target="_blank" href={data.twitter}>
                                      <i className="fab fa-twitter" />
                                    </a>
                                  ) : (
                                    <></>
                                  )}
                                  {data.googleplus ? (
                                    <a target="_blank" href={data.googleplus}>
                                      <i className="fab fa-google-plus" />
                                    </a>
                                  ) : (
                                    <></>
                                  )}
                                  {data.linkedin ? (
                                    <a target="_blank" href={data.linkedin}>
                                      <i className="fab fa-linkedin" />
                                    </a>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                {/*/ social-icons*/}
                              </div>
                            </div>
                            {/*/ Team wrapper end */}
                          </div>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                  {/* Team slide end */}
                </div>
              </div>
              {/*/ Content row end */}
            </div>
            {/*/ Container end */}
          </section>
          {/*/ Team end */}
        </>
      </>
    );
  }
}

export default Team;
