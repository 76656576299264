import React, { Component } from "react";
import urls from "../Helpers/urls";

class Slider extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <>
        <div className="banner-carousel banner-carousel-1 mb-0">
          <div
            className="banner-carousel-item"
            style={{
              backgroundImage: `url(${require(`./../images/slider-main/${this.props.data.banner1.image}`)})`,
            }}
          >
            <div className="slider-content">
              <div className="container h-100">
                <div className="row align-items-center h-100">
                  <div className="col-md-12 text-center">
                    <h2
                      className="slide-title"
                      style={{ color: this.props.data.banner1.color }}
                      data-animation-in="slideInLeft"
                    >
                      {this.props.data.banner1.heading1}
                    </h2>
                    <h3
                      className="slide-sub-title"
                      style={{ color: this.props.data.banner1.color }}
                      data-animation-in="slideInRight"
                    >
                      {this.props.data.banner1.heading2}
                    </h3>
                    <p data-animation-in="slideInLeft" data-duration-in="1.2">
                      <a
                        href={urls.services}
                        className="slider btn btn-primary"
                      >
                        Our Services
                      </a>
                      <a
                        href={urls.contactUs}
                        className="slider btn btn-primary"
                      >
                        Contact Now
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="banner-carousel-item"
            style={{
              backgroundImage: `url(${require(`./../images/slider-main/${this.props.data.banner2.image}`)})`,
            }}
          >
            <div className="slider-content text-left">
              <div className="container h-100">
                <div className="row align-items-center h-100">
                  <div className="col-md-12">
                    <h2
                      className="slide-title-box"
                      data-animation-in="slideInDown"
                    >
                      {this.props.data.banner2.heading1}
                    </h2>
                    <h3 className="slide-title" data-animation-in="fadeIn">
                      {this.props.data.banner2.heading2}
                    </h3>
                    <h3
                      className="slide-sub-title"
                      data-animation-in="slideInLeft"
                    >
                      {this.props.data.banner2.heading3}
                    </h3>
                    <p data-animation-in="slideInRight">
                      <a
                        href={urls.services}
                        className="slider btn btn-primary border"
                      >
                        Our Services
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="banner-carousel-item"
            style={{
              backgroundImage: `url(${require(`./../images/slider-main/${this.props.data.banner3.image}`)})`,
            }}
          >
            <div className="slider-content text-right">
              <div className="container h-100">
                <div className="row align-items-center h-100">
                  <div className="col-md-12">
                    <h2 className="slide-title" data-animation-in="slideInDown">
                      {this.props.data.banner3.heading1}
                    </h2>
                    <h3 className="slide-sub-title" data-animation-in="fadeIn">
                      {this.props.data.banner3.heading2}
                    </h3>
                    <p
                      className="slider-description lead"
                      data-animation-in="slideInRight"
                    >
                      {this.props.data.banner3.heading3}
                    </p>
                    <div data-animation-in="slideInLeft">
                      <button
                        data-toggle="modal"
                        data-target="#exampleModal"
                        className="slider btn btn-primary"
                        aria-label="contact-with-us"
                      >
                        Get Free Quote
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="call-to-action-box no-padding">
          <div className="container">
            <div className="action-style-box">
              <div className="row align-items-center">
                <div className="col-md-8 text-center text-md-left">
                  <div className="call-to-action-text">
                    <h3 className="action-title">
                      We understand your needs on construction
                    </h3>
                  </div>
                </div>
                {/* Col end */}
                <div className="col-md-4 text-center text-md-right mt-3 mt-md-0">
                  <div className="call-to-action-btn">
                    <button
                      className="btn btn-dark"
                      data-toggle="modal"
                      data-target="#exampleModal"
                    >
                      Request Quote
                    </button>
                  </div>
                </div>
                {/* col end */}
              </div>
              {/* row end */}
            </div>
            {/* Action style box */}
          </div>
          {/* Container end */}
        </section>
      </>
    );
  }
}

export default Slider;
