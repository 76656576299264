import React, { Component } from "react";

class WhatWeDo extends Component {
  state = {};
  render() {
    return (
      <>
        <section id="ts-service-area" className="ts-service-area pb-0">
          <div className="container">
            <div className="row text-center">
              <div className="col-12">
                <h2 className="section-title">{this.props.data.heading1}</h2>
                <h3 className="section-sub-title m-0">
                  {this.props.data.heading2}
                </h3>
                <p className="mb-5">
                  We build your <span className="glow">UTOPIA</span> by
                  providing integrated Engineering services from{" "}
                  <span className="glow">THINK to DONE</span> stage.
                </p>
              </div>
            </div>
            {/*/ Title row end */}
            <div className="row">
              <div className="col-lg-4">
                {this.props.data.leftgroup &&
                this.props.data.leftgroup.length > 0 ? (
                  this.props.data.leftgroup.map((data, index) => {
                    return (
                      <div key={index} className="ts-service-box d-flex">
                        <div className="ts-service-box-img">
                          <img
                            loading="lazy"
                            src={require(`./../images/icon-image/${data.image}`)}
                            alt="service-icon"
                          />
                        </div>
                        <div className="ts-service-box-info">
                          <h3 className="service-box-title">
                            <a href="#">{data.title}</a>
                          </h3>
                          <p>{data.description}</p>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <></>
                )}
              </div>
              {/* Col end */}
              <div
                className="col-lg-4 text-center"
                // style={{ writingMode: "vertical-rl" }}
              >
                <img
                  loading="lazy"
                  className="img-fluid"
                  src={require(`./../images/services/${this.props.data.mainImage}`)}
                  alt="service-avater-image"
                />
              </div>
              {/* Col end */}
              <div className="col-lg-4 mt-5 mt-lg-0 mb-4 mb-lg-0">
                {this.props.data.rightGroup &&
                this.props.data.rightGroup.length > 0 ? (
                  this.props.data.rightGroup.map((data, index) => {
                    return (
                      <div key={index} className="ts-service-box d-flex">
                        <div className="ts-service-box-img">
                          <img
                            loading="lazy"
                            src={require(`./../images/icon-image/${data.image}`)}
                            alt="service-icon"
                          />
                        </div>
                        <div className="ts-service-box-info">
                          <h3 className="service-box-title">
                            <a href="#">{data.title}</a>
                          </h3>
                          <p>{data.description}</p>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <></>
                )}
              </div>
              {/* Col end */}
            </div>
            {/* Content row end */}
          </div>
          {/*/ Container end */}
        </section>
      </>
    );
  }
}

export default WhatWeDo;
