import React, { Component } from "react";
import db from "./../db.json";
import LocationHeader from "./LocationHeader";
import MainHeader from "./MainHeader";

import Footer from "./Footer";
import BannerArea from "./BannerArea";
import Team from "./Team";
class TermsPage extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <>
        <LocationHeader data={db.locationHeader} />
        <MainHeader data={db.mainHeader} menus={db.Menus} />
        <BannerArea data={db.terms} />

        <>
          <section id="main-container" className="main-container">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h3 className="column-title">{db.terms.heading2}</h3>
                  {/* {db.terms.paras && db.terms.paras.length > 0 ? (
                    db.terms.paras.map((data, index) => {
                      return (
                        <p key={index} style={{ textAlign: "justify" }}>
                          {data.desc}
                        </p>
                      );
                    })
                  ) : (
                    <></>
                  )} */}
                  <ol className="terms">
                    <li className="mt-2">
                      Constructopia reserves the right, by giving notice to the
                      client at any time before delivery, to increase the price
                      of the Goods/Services to reflect any increase in the cost
                      to Constructopia which is due to any factor beyond the
                      control of Constructopia.
                    </li>
                    <li className="mt-2">
                      The amount due for the purchase by the client of the
                      Goods/Services (together with any freight, duty,
                      insurance, or other taxes) shall be paid (without
                      deduction or set off) on or before the 7th day after the
                      date of Constructopia’s invoice. Time for payment shall be
                      of the essence.
                    </li>
                    <li className="mt-2">
                      Constructopia may from time to time make changes in the
                      quantity, quality, and description of and any
                      specification for the Goods which are required to comply
                      with any applicable safety or statutory requirements or
                      which do not materially affect the quality or fitness for
                      use of the Goods.
                    </li>
                    <li className="mt-2">
                      Constructopia shall not be liable to the client or be
                      deemed to be in breach of the Contract by reason of any
                      delay in performing, or any failure to perform, any of
                      Constructopia’s obligations in relation to the Goods, if
                      the delay or failure was due to any cause beyond
                      Constructopia’s reasonable control.
                    </li>
                    <li className="mt-2">
                      Any dates quoted for delivery of the Goods/Services are
                      approximate only. Constructopia shall not be liable for
                      any delay in delivery of the Goods/Services however
                      caused. Time for delivery shall not be of the essence of
                      the Contract.
                    </li>
                    <li className="mt-2">
                      If the Client fails to take delivery of the Goods/Services
                      or fails to give Constructopia adequate delivery
                      instructions at the time stated for delivery (otherwise
                      than by reason of any cause beyond the client’s reasonable
                      control or by reason of Constructopia’s fault) then,
                      without prejudice to any other right or remedy available
                      to Constructopia, Constructopia shall be entitled to:
                      <ol type="a" className="mt-2">
                        <li className="mt-2">
                          store the Goods/Services until actual delivery and
                          charge the client for the reasonable costs (including
                          insurance) of storage;
                        </li>
                        <li className="mt-2">
                          take all steps it deems necessary to prevent the
                          deterioration of the Goods/Services and charge the
                          Client for the reasonable costs incurred thereby;
                        </li>
                        <li className="mt-2">
                          without any reference to the Client:
                          <ol type="i">
                            <li className="mt-2">
                              Where the Client fails to make full payment of the
                              price for the Goods/Services on the due date or is
                              otherwise in breach of its obligations under the
                              Contract, treat the Contract as repudiated by the
                              Client and sell the Goods/Services at the best
                              price readily obtainable or otherwise dispose of
                              the Goods/Services;
                            </li>
                            <li className="mt-2">
                              If the Client has made full payment of the price
                              for the Goods/Services on the due date but is
                              otherwise in breach of its obligations under the
                              Contract, sell the Goods/Services at the best
                              price readily obtainable or otherwise dispose of
                              the Goods/Services and claim any loss on resale
                              from the Client. Notwithstanding the
                              Goods/Services being sold or disposed of at a
                              higher price, Constructopia shall only be obliged
                              to account to the client for the price of the
                              Goods/Services under the Contract after deducting
                              all reasonable storage and selling expenses.
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li className="mt-2">
                      The Client shall inspect the Goods/Services on delivery
                      and shall, within seven days of delivery, notify
                      Constructopia of any alleged defect or failure to comply
                      with the description or shortage in quantity. The Client
                      shall then afford Constructopia an opportunity to inspect
                      the Goods/Services within a reasonable time following
                      delivery and before any use is made of them. If the Client
                      shall fail to comply with these provisions, the
                      Goods/Services shall be conclusively presumed to be in
                      accordance with the Contract and free from any defect,
                      damage, or shortage, and the Client shall be deemed to
                      have accepted the Goods/Services.
                    </li>
                    <li className="mt-2">
                      If the Goods/Services are not in accordance with the
                      Contract for any reason and the Client has duly given
                      Constructopia notice, the Client’s sole remedy shall be
                      limited to Constructopia making good any alleged defect,
                      shortage in quantity or failure to comply with description
                      by replacing or effecting rectification of such
                      Goods/Services at Constructopia’s discretion or, if
                      Constructopia shall elect, by refunding a proportionate
                      part of the price for the Goods/Services under the
                      Contract. Constructopia shall not be liable for any damage
                      caused either to the Goods/Services or as a result of the
                      malfunction of the Goods/Services if the Goods are:
                      <ol className="mt-2" type="a">
                        <li className="mt-2">
                          fitted by unqualified tradesmen;
                        </li>
                        <li className="mt-2">
                          fitted in an unstatesmanlike manner;
                        </li>
                        <li className="mt-2">
                          in any way adapted for a use for which they are not
                          specifically intended; or
                        </li>
                        <li className="mt-2">
                          added to or repaired using components not recommended
                          or approved by Constructopia or the manufacturer of
                          such components.
                        </li>
                      </ol>
                    </li>
                    <li className="mt-2">
                      Where the Client rejects any Goods/Services, the Client
                      shall have no further rights whatever in respect of the
                      supply to the Client of such Goods by Constructopia.
                    </li>
                    <li className="mt-2">
                      If the Client fails to make full payment on the due date
                      or is otherwise in breach of its obligations under the
                      Contract, then without prejudice to any other right or
                      remedy available to Constructopia, Constructopia shall be
                      entitled to cancel the Contract or suspend any further
                      deliveries to the Client, appropriate any payment made by
                      the Client to such of the Goods as Constructopia may think
                      fit and charge the client daily interest (both before and
                      after any judgement) on the amount unpaid at the rate of
                      2.5% per month until payment in full is made and the
                      client shall also be liable for all of Constructopia’s
                      costs and expenses (including legal costs on an indemnity
                      basis) incurred as a result of a default by the client
                      under these Conditions and/or any Contract.
                    </li>
                    <li className="mt-2">
                      The Client shall not withhold payment of any amount due to
                      Constructopia by reason of any right or alleged right of
                      set-off or counterclaim whatsoever.
                    </li>
                    <li className="mt-2">
                      Any liability to the Client and the price for any
                      Goods/Services already delivered shall become payable
                      forthwith notwithstanding any agreement to the contrary.
                    </li>
                    <li className="mt-2">
                      If any of the terms of these Conditions or the Contract
                      are held by a Court in New Zealand to be ineffective by
                      virtue of non-registration, illegality, or otherwise, then
                      such term, or part of it, shall be severed from all other
                      terms. Unenforceability of a provision of these Conditions
                      does not affect the enforceability of any other provision
                      of these Conditions.
                    </li>
                    <li className="mt-2">
                      The Client shall not be entitled to assign any of its
                      rights under any Contract without the prior written
                      consent of Constructopia.
                    </li>
                    <li className="mt-2">
                      Constructopia is independent of the Client and nothing in
                      these Conditions or any Contract will constitute any other
                      type of relationship between the Client and Constructopia
                      other than as vendor and purchaser.
                    </li>
                    <li className="mt-2">
                      The Contract and these Conditions shall be governed by and
                      interpreted in accordance with the laws of New Zealand and
                      the Client and Constructopia submit to the non-exclusive
                      jurisdiction of the Courts of New Zealand.
                    </li>
                    <li className="mt-2">
                      The Client authorizes Constructopia to collect and hold
                      personal information from any source Constructopia
                      considers appropriate to be used for the purpose of
                      determining credit worthiness, for communication of
                      product information by Constructopia, for debt collection
                      purposes, or any other related purpose. The Client
                      authorizes Constructopia to disclose personal information
                      held by Constructopia for the above purposes to any third
                      party.
                    </li>
                    <li className="mt-2">
                      Constructopia reserves the right to amend and vary these
                      Conditions from time to time. Notice of such variation
                      shall be deemed to be given to a Client by Constructopia
                      listing a notice of such variation on its website at{" "}
                      <a href="www.Constructopia.co.nz">
                        www.Constructopia.co.nz
                      </a>{" "}
                      together with the date on which any amendment or variation
                      becomes effective.
                    </li>
                    <li className="mt-2">
                      If a Communicable Disease Event occurs that causes delay
                      in the completion of the Contract Works or additional Cost
                      to the Contractor, the Communicable Disease Event shall be
                      treated as a Variation.
                    </li>
                    <li className="mt-2">
                      Where CONSTRUCTOPIA has reasonable cause to believe that
                      the Client has not strictly complied with these Conditions
                      and, in particular, has not effected a payment in
                      accordance with these Conditions, or the Client has or
                      will commit an act of bankruptcy or (being a company) has
                      a receiver, liquidator or statutory manager appointed
                      then, notwithstanding section 109 of the Personal Property
                      Securities Act 1999 (the “PPSA”), and in addition to the
                      rights contained in that section, CONSTRUCTOPIA may
                      recover any or all of the Goods/Services or the mixed
                      goods and re-sell the Goods/Services or the mixed goods
                      and for such purpose may at any time of the day or night
                      enter by force if necessary upon any premises where such
                      Goods/Services or mixed goods are reasonably thought to be
                      stored (and the Client grants to CONSTRUCTOPIA an
                      irrevocable right and authority to so recover, re-enter
                      and re-sell).
                    </li>
                    <li className="mt-2">
                      If the Client shall be declared bankrupt or commit an act
                      of bankruptcy or enter into a scheme or arrangement or
                      make any assignment for the benefit of creditors or being
                      a Company has a receiver, liquidator, or statutory manager
                      appointed or an application for its winding up filed in
                      any court or enters into any scheme or arrangement or
                      assignment or composition for the benefit of creditors, or
                      ceases or threatens to cease to carry on business, then
                      without prejudice to any other right or remedy available
                      to Constructopia, Constructopia shall be entitled to
                      cancel the Contract or suspend any further deliveries
                      under the Contract without
                    </li>
                  </ol>
                </div>
                {/* Col end */}

                {/* Col end */}
              </div>
              {/* Content row end */}
            </div>
            {/* Container end */}
          </section>
          {/* Main container end */}
        </>

        <Footer
          data={db.footers}
          header={db.locationHeader}
          services={db.services}
        />
      </>
    );
  }
}

export default TermsPage;
