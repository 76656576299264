import React, { Component } from "react";

class ProjectSingle extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <>
        <>
          <section id="main-container" className="main-container">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div id="page-slider" className="page-slider small-bg">
                    {this.props.data.imagesdata &&
                    this.props.data.imagesdata.length > 0 ? (
                      this.props.data.imagesdata.map((i, index) => {
                        return (
                          <div className="item">
                            <img
                              key={index}
                              loading="lazy"
                              className="img-fluid"
                              src={require(`./../images/projects/${i.image}`)}
                              alt="project-image"
                            />
                          </div>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                  {/* Page slider end */}
                </div>
                {/* Slider col end */}
                <div className="col-lg-6 mt-5 mt-lg-0">
                  <h3 className="column-title mrt-0">
                    {this.props.data.title}
                  </h3>
                  {this.props.data.paras && this.props.data.paras.length > 0 ? (
                    this.props.data.paras.map((p, index) => {
                      return <p key={index}>{p.desc}</p>;
                    })
                  ) : (
                    <></>
                  )}

                  {this.props.data.videoLink ? (
                    <iframe
                      width={560}
                      height={315}
                      src={this.props.data.videoLink}
                      title="YouTube video player"
                      frameBorder={0}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen=""
                    />
                  ) : (
                    <></>
                  )}
                </div>
                {/* Content col end */}
              </div>
              {/* Row end */}
            </div>
            {/* Conatiner end */}
          </section>
          {/* Main container end */}
        </>
      </>
    );
  }
}

export default ProjectSingle;
