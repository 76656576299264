import logo from "./logo.svg";
import "./App.css";
import db from "./db.json";
import HomePage from "./Components/HomePage";
import AboutUsPage from "./Components/AboutUsPage";
import { Routes, Route, useParams } from "react-router-dom";
import FaqPage from "./Components/FaqPage";
import ContactUsPage from "./Components/ContactusPage";
import Projects from "./Components/Projects";
import ProjectPage from "./Components/ProjectsPage";
import ServicePage from "./Components/ServicePage";

import React, { Component } from "react";
import FourzeroFourPage from "./Components/FourzeroFourPage";
import ProjectSinglePage from "./Components/ProjectSinglePage";
import TermsPage from "./Components/TermsPage";

function App() {
  const params = useParams();
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/AboutUs" element={<AboutUsPage />} />
      <Route path="/Faq" element={<FaqPage />} />
      <Route path="/Contactus" element={<ContactUsPage />} />
      <Route path="/Projects" element={<ProjectPage />} />
      <Route path="/Services" element={<ServicePage />} />
      <Route path="/projectDetail" element={<ProjectSinglePage />} />
      <Route path="/terms" element={<TermsPage />} />

      <Route path="*" element={<FourzeroFourPage />} />
      {/* ... etc. */}
    </Routes>
  );
}

export default App;
