import React, { Component } from "react";
import Fuse from "fuse.js";
import { ToastContainer, toast } from "react-toastify";
class Menus extends Component {
  constructor(props) {
    super(props);
  }

  state = { search: "" };

  handleKeyPress = (e) => {
    const data = [
      { id: 2, title: "Home", submenu: [], route: "/" },
      { id: 2, title: "TestiMonials", route: "/#testimonials" },
      {
        id: 3,
        title: "About us",
        route: "/AboutUs",
      },
      {
        id: 1,
        title: "Commercial Cum Residential Complex",
        route: "/projectDetail?id=1",
      },
      {
        id: 2,
        title: "THE ACADEMY OF CHAMPIONS, ISLAMABAD",
        route: "/projectDetail?id=2",
      },
      {
        id: 3,
        title: "Interior Modelling",
        route: "/projectDetail?id=3",
      },
      {
        id: 4,
        title: "Exterior Modelling",
        route: "/projectDetail?id=4",
      },
      {
        id: 4,
        title: "Projects",

        route: "/Projects",
      },

      {
        id: 1,
        title: "Drawings/Drafting (2D models)",
        route: "/Services?id=1",
      },
      { id: 2, title: "3D Modelling", route: "/Services?id=2" },
      {
        id: 3,
        title: "Budget & Costing/Estimate",
        route: "/Services?id=3",
      },
      {
        id: 4,
        title: "Steel Detailing / Bar Bending Schedule",
        route: "/Services?id=4",
      },
      { id: 5, title: "Management Plans", route: "/Services?id=5" },
      {
        id: 6,
        title: "Planning / Programming",
        route: "/Services?id=6",
      },

      {
        id: 7,
        title: "SITE INSPECTIONS / PROJECT MANAGEMENT",
        route: "/Services?id=7",
      },
      { id: 8, title: "Construction Survey", route: "/Services?id=8" },
      {
        id: 5,
        title: "Services",
        submenu: [],
        route: "/Services?id=1",
      },
      { id: 6, title: "Contact", submenu: [], route: "/Contactus" },
      { id: 7, title: "FAQ", route: "/Faq", submenu: [] },
      { id: 7, title: "T&C", route: "/terms", submenu: [] },
    ];

    if (e.key === "Enter" && this.state.search.length > 0) {
      // Trigger your action here
      // Create a new Fuse instance with your data and search options
      var fuse = new Fuse(data, {
        keys: ["title"], // The property to search on
        threshold: 0.3, // Adjust the threshold for fuzzy matching
      });

      // Your search term
      var searchTerm = this.state.search; // Replace with your search term

      // Perform the search and get the result
      const result = fuse.search(searchTerm);

      // If there's a match, result will contain the matching objects
      if (result.length > 0) {
        const bestMatch = result[0].item;
        if (bestMatch && bestMatch.route) {
          window.location = bestMatch.route;
        }
      } else {
        toast.error("No result were found.");
      }
    }
  };

  handlechange = (e) => {
    this.setState({ search: e.target.value });
  };
  render() {
    return (
      <>
        <div className="site-navigation">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <nav className="navbar navbar-expand-lg navbar-dark p-0">
                  {this.props.data && this.props.data.length > 0 ? (
                    <>
                      <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target=".navbar-collapse"
                        aria-controls="navbar-collapse"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <span className="navbar-toggler-icon" />
                      </button>
                      <div
                        id="navbar-collapse"
                        className="collapse navbar-collapse"
                      >
                        <ul className="nav navbar-nav mr-auto">
                          {this.props.data && this.props.data.length > 0 ? (
                            this.props.data.map((data, i) => {
                              return (
                                <>
                                  {data.submenu && data.submenu.length > 0 ? (
                                    <>
                                      {" "}
                                      <li key={i} className="nav-item dropdown">
                                        <a
                                          href="#"
                                          className="nav-link dropdown-toggle"
                                          data-toggle="dropdown"
                                        >
                                          {data.title.toUpperCase()}{" "}
                                          <i className="fa fa-angle-down" />
                                        </a>

                                        <ul
                                          className="dropdown-menu"
                                          role="menu"
                                        >
                                          {data.submenu.map((sub, index) => {
                                            return (
                                              <li key={index}>
                                                <a href={sub.route}>
                                                  {sub.title.toUpperCase()}
                                                </a>
                                              </li>
                                            );
                                          })}
                                        </ul>
                                      </li>
                                    </>
                                  ) : (
                                    <li className="nav-item">
                                      <a className="nav-link" href={data.route}>
                                        {data.title}
                                      </a>
                                    </li>
                                  )}
                                </>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </ul>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </nav>
              </div>
              {/*/ Col end */}
            </div>
            {/*/ Row end */}
            <>
              <div className="nav-search">
                <span id="search">
                  <i className="fa fa-search" />
                </span>
              </div>
              {/* Search end */}
              <div className="search-block" style={{ display: "none" }}>
                <label htmlFor="search-field" className="w-100 mb-0">
                  <input
                    type="text"
                    className="form-control"
                    id="search-field"
                    onChange={(e) => this.handlechange(e)}
                    onKeyDown={(e) => this.handleKeyPress(e)}
                    value={this.state.search}
                    placeholder="Type what you want and enter"
                  />
                </label>
                <span className="search-close">×</span>
              </div>
              {/* Site search end */}
            </>
          </div>
          {/*/ Container end */}
          <ToastContainer />
        </div>
      </>
    );
  }
}

export default Menus;
