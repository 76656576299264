import React, { Component } from "react";
import urls from "./../Helpers/urls";
import Menus from "./Menus";
import QuoteModal from "./QuoteModal";
class MainHeader extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <>
        {/* Header start */}
        <header id="header" className="header-one">
          <div className="bg-white">
            <div className="container">
              <div className="logo-area">
                <div className="row align-items-center">
                  <div className="logo col-lg-3 text-center text-lg-left mb-3 mb-md-5 mb-lg-0">
                    <a className="d-block" href="/">
                      <img
                        loading="lazy"
                        src={require(`./../images/${this.props.data.logoFile}`)}
                        alt="Constructopia"
                        style={{ width: "9rem", height: "auto" }}
                      />
                    </a>
                  </div>
                  {/* logo end */}
                  <div className="col-lg-9 header-right">
                    <ul className="top-info-box">
                      {this.props.data.callUs ? (
                        <li>
                          <div className="info-box">
                            <div className="info-box-content">
                              <p className="info-box-title">Call Us</p>
                              <p className="info-box-subtitle">
                                {this.props.data.callUs}
                              </p>
                            </div>
                          </div>
                        </li>
                      ) : (
                        <></>
                      )}

                      {this.props.data.emailUs ? (
                        <li
                          className={
                            this.props.data.globalCertificate ? "" : "last"
                          }
                        >
                          <div className="info-box">
                            <div className="info-box-content">
                              <p className="info-box-title">Email Us</p>
                              <p className="info-box-subtitle">
                                {this.props.data.emailUs}
                              </p>
                            </div>
                          </div>
                        </li>
                      ) : (
                        <></>
                      )}

                      {this.props.data.globalCertificate ? (
                        <li className="last">
                          <div className="info-box last">
                            <div className="info-box-content">
                              <p className="info-box-title">
                                Global Certificate
                              </p>
                              <p className="info-box-subtitle">
                                {this.props.data.globalCertificate}
                              </p>
                            </div>
                          </div>
                        </li>
                      ) : (
                        <></>
                      )}
                      {this.props.data.showGetAllowed ? (
                        <li className="header-get-a-quote">
                          <button
                            className="btn btn-primary"
                            data-toggle="modal"
                            data-target="#exampleModal"
                          >
                            Get A Quote
                          </button>
                        </li>
                      ) : (
                        <></>
                      )}
                    </ul>
                    {/* Ul end */}
                  </div>
                  {/* header right end */}
                </div>
                {/* logo area end */}
              </div>
              {/* Row end */}
            </div>
            {/* Container end */}
          </div>
          <Menus data={this.props.menus} />
          {/*/ Navigation end */}
        </header>
        {/*/ Header end */}
        <QuoteModal />
      </>
    );
  }
}

export default MainHeader;
