import React, { Component } from "react";

class Services extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <>
        <>
          <section id="main-container" className="main-container">
            <div className="container">
              <div className="row">
                <div className="col-xl-3 col-lg-4">
                  <div className="sidebar sidebar-left">
                    <div className="widget">
                      <h3 className="widget-title">Solutions</h3>
                      <ul className="nav service-menu">
                        {this.props.services &&
                        this.props.services.length > 0 ? (
                          this.props.services.map((data, index) => {
                            return (
                              <li
                                key={index}
                                className={
                                  data.id === this.props.selectedService?.id
                                    ? "active"
                                    : ""
                                }
                              >
                                <a href={`/Services?id=${data.id}`}>
                                  {data.title}
                                </a>
                              </li>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </ul>
                    </div>
                    {/* Widget end */}

                    {/* Widget end */}
                  </div>
                  {/* Sidebar end */}
                </div>
                {/* Sidebar Col end */}
                <div className="col-xl-8 col-lg-8">
                  <div className="content-inner-page">
                    <h2 className="column-title mrt-0">
                      {this.props.selectedService.heading}
                    </h2>
                    <div className="row">
                      <div className="col-md-12">
                        {this.props.selectedService.paras &&
                        this.props.selectedService.paras.length > 0 ? (
                          this.props.selectedService.paras.map((p, index) => {
                            return (
                              <p key={index} style={{ textAlign: "justify" }}>
                                {p.description}
                              </p>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </div>
                      {/* col end */}
                    </div>
                    {/* 1st row end*/}
                    {this.props.selectedService.images ? (
                      <>
                        <div className="gap-40" />
                        <div id="page-slider" className="page-slider">
                          {this.props.selectedService.images.length > 0 ? (
                            this.props.selectedService.images.map((d, i) => {
                              return (
                                <div key={i} className="item">
                                  <img
                                    loading="lazy"
                                    className="img-fluid"
                                    src={require(`./../images/projects/${d.image}`)}
                                    alt="project-slider-image"
                                  />
                                </div>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {/*2nd row end */}
                    <div className="gap-40" />
                    <div className="call-to-action classic">
                      <div className="row align-items-center">
                        <div className="col-md-8 text-center text-md-left">
                          <div className="call-to-action-text">
                            <h3 className="action-title">
                              Interested with this service.
                            </h3>
                          </div>
                        </div>
                        {/* Col end */}
                        <div className="col-md-4 text-center text-md-right mt-3 mt-md-0">
                          <div className="call-to-action-btn">
                            <button
                              className="btn btn-primary"
                              data-toggle="modal"
                              data-target="#exampleModal"
                            >
                              Get a Quote
                            </button>
                          </div>
                        </div>
                        {/* col end */}
                      </div>
                      {/* row end */}
                    </div>
                    {/* Action end */}
                  </div>
                  {/* Content inner end */}
                </div>
                {/* Content Col end */}
              </div>
              {/* Main row end */}
            </div>
            {/* Conatiner end */}
          </section>
          {/* Main container end */}
        </>
      </>
    );
  }
}

export default Services;
