import axios from "axios";
import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
class ConatctusContainer extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    name: "",
    message: "",
    email: "",
    subject: "",
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    if (
      this.state.email.length > 0 &&
      this.state.name.length > 0 &&
      this.state.message.length > 0 &&
      this.state.subject.length > 0
    ) {
      const headers = {
        "Content-Type": "text/plain",
      };
      var emaildata = {
        name: this.state.name,
        message: this.state.message,
        email: this.state.email,
        subject: this.state.subject,
      };
      try {
        await axios.post(
          "https://constructopia-api.netlify.app/.netlify/functions/api/send-email",
          emaildata,
          { headers }
        );
        toast.success("Email sent successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } catch (error) {
        toast.error("Email sending failed. Please try again later.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      toast.error("Please fill all fields before continue", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  render() {
    return (
      <>
        <>
          <section id="main-container" className="main-container">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {this.props.data.paras && this.props.data.paras.length > 0 ? (
                    this.props.data.paras.map((par, index) => {
                      return (
                        <p key={index} style={{ textAlign: "justify" }}>
                          {par.description}
                        </p>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="row text-center">
                <div className="col-12">
                  <h2 className="section-title">{this.props.data.heading2}</h2>
                  <h3 className="section-sub-title">
                    {this.props.data.heading3}
                  </h3>
                </div>
              </div>

              {/*/ Title row end */}
              <div className="row">
                {this.props.data.address ? (
                  <div className="col-md-4">
                    <div className="ts-service-box-bg text-center h-100">
                      <span className="ts-service-icon icon-round">
                        <a href={this.props.data.addresslink}>
                          <i className="fas fa-map-marker-alt mr-0" />
                        </a>
                      </span>
                      <div className="ts-service-box-content">
                        <h4>{this.props.data.addressheading}</h4>
                        <p>{this.props.data.address}</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {/* Col 1 end */}
                {this.props.data.email ? (
                  <div className="col-md-4">
                    <div className="ts-service-box-bg text-center h-100">
                      <span className="ts-service-icon icon-round">
                        <i className="fa fa-envelope mr-0" />
                      </span>
                      <div className="ts-service-box-content">
                        <h4>{this.props.data.emailheading}</h4>
                        <p>{this.props.data.email}</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {this.props.data.phone ? (
                  <div className="col-md-4">
                    <div className="ts-service-box-bg text-center h-100">
                      <span className="ts-service-icon icon-round">
                        <i className="fa fa-phone-square mr-0" />
                      </span>
                      <div className="ts-service-box-content">
                        <h4>{this.props.data.phoneheading}</h4>
                        <p>{this.props.data.phone}</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {/* Col 2 end */}

                {/* Col 3 end */}
              </div>
              {/* 1st row end */}
              <div className="gap-60" />
              {/* <div className="google-map">
                {this.props.data.markers &&
                this.props.data.markers.length > 0 ? (
                  this.props.data.markers.map((data) => {
                    return (
                      <div
                        id="map"
                        className="map"
                        data-latitude={data.lat}
                        data-longitude={data.long}
                        data-marker={require("./../images/marker.png")}
                        data-marker-name={data.name}
                      />
                    );
                  })
                ) : (
                  <></>
                )}
              </div> */}
              <div className="col-12">
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3190.034551271426!2d174.6935363759543!3d-36.91343828312223!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d46ae67191ed1%3A0x7db7158c74b2932f!2s2%2F75%20Taylor%20Street%2C%20Blockhouse%20Bay%2C%20Auckland%200600%2C%20New%20Zealand!5e0!3m2!1sen!2sus!4v1695403487326!5m2!1sen!2sus"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  />

                  {/* <iframe
                    src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3190.032606929064!2d174.6936786757242!3d-36.91348477221497!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzbCsDU0JzQ4LjYiUyAxNzTCsDQxJzQ2LjUiRQ!5e0!3m2!1sen!2s!4v1695152787842!5m2!1sen!2s"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  /> */}
                </div>
              </div>

              <div className="gap-40" />

              <div className="row">
                <div className="col-md-6">
                  <div className="ts-service-box-bg text-center h-100">
                    <span className="ts-service-icon icon-round">
                      <i className="fas fa-map-marker-alt mr-0" />
                    </span>
                    <div className="ts-service-box-content">
                      <h4>US, CANADA AND EUROPE</h4>
                      <p>
                        1758 Elston Circle Woodland California, 95776, US <br />
                        Phone No : +1 209 787 7010 <br />
                        Phone No : +1 (424) 458 3111
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="ts-service-box-bg text-center h-100">
                    <span className="ts-service-icon icon-round">
                      <i className="fas fa-map-marker-alt mr-0" />
                    </span>
                    <div className="ts-service-box-content">
                      <h4>ASIA AND MIDDLE EAST</h4>
                      <p>
                        57G Street 7 Phase 8 DHA, Lahore, 54000 <br />
                        Phone No : +92 344 8744477
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="gap-40" />
              <div className="row">
                <div className="col-md-12">
                  <h3 className="column-title">We love to hear</h3>
                  {/* contact form works with formspree.io  */}
                  {/* contact form activation doc: https://docs.themefisher.com/constra/contact-form/ */}
                  <form
                    id="contact-form"
                    method="post"
                    role="form"
                    onSubmit={(e) => this.handleSubmit(e)}
                  >
                    <div className="error-container" />
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Name</label>
                          <input
                            className="form-control form-control-name"
                            onChange={(e) => this.handleChange(e)}
                            value={this.state.name}
                            name="name"
                            id="name"
                            placeholder=""
                            type="text"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Email</label>
                          <input
                            className="form-control form-control-email"
                            name="email"
                            id="email"
                            placeholder=""
                            type="email"
                            value={this.state.email}
                            onChange={(e) => this.handleChange(e)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Subject</label>
                          <input
                            className="form-control form-control-subject"
                            name="subject"
                            id="subject"
                            placeholder=""
                            value={this.state.subject}
                            onChange={(e) => this.handleChange(e)}
                            required=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Message</label>
                      <textarea
                        className="form-control form-control-message"
                        name="message"
                        id="message"
                        placeholder=""
                        value={this.state.message}
                        onChange={(e) => this.handleChange(e)}
                        rows={10}
                        required=""
                      />
                    </div>
                    <div className="text-right">
                      <br />
                      <button
                        className="btn btn-primary solid blank"
                        type="submit"
                      >
                        Send Message
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              {/* Content row */}
            </div>
            {/* Conatiner end */}
            <ToastContainer />
          </section>
          {/* Main container end */}
        </>
      </>
    );
  }
}

export default ConatctusContainer;
