import React, { Component } from "react";

class Projects extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <>
        <section id="project-area" className="project-area solid-bg">
          <div className="container">
            <div className="row text-center">
              <div className="col-lg-12">
                <h2 className="section-title">{this.props.data.heading1}</h2>
                <h3 className="section-sub-title">
                  {this.props.data.heading2}
                </h3>
              </div>
            </div>
            {/*/ Title row end */}
            <div className="row">
              <div className="col-12">
                <div className="shuffle-btn-group">
                  <label className="active" htmlFor="all">
                    <input
                      type="radio"
                      name="shuffle-filter"
                      id="all"
                      defaultValue="all"
                      defaultChecked="checked"
                    />
                    Show All
                  </label>
                  {this.props.data.categories &&
                  this.props.data.categories.length > 0 ? (
                    this.props.data.categories.map((cat, index) => {
                      return (
                        <label key={index} htmlFor={cat.category}>
                          <input
                            type="radio"
                            name="shuffle-filter"
                            id={cat.category}
                            defaultValue={cat.category}
                          />
                          {cat.title}
                        </label>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>
                {/* project filter end */}
                <div className="row shuffle-wrapper">
                  <div className="col-1 shuffle-sizer" />
                  {this.props.data.details &&
                  this.props.data.details.length > 0 ? (
                    this.props.data.details.map((data, index) => {
                      return (
                        <div
                          key={index}
                          className="col-lg-4 col-md-6 shuffle-item"
                          data-groups={data.datagroup}
                        >
                          <div className="project-img-container">
                            <a
                              className="gallery-popup"
                              href={require(`./../images/projects/${data.image}`)}
                              aria-label="project-img"
                            >
                              <img
                                className="img-fluid"
                                src={require(`./../images/projects/${data.image}`)}
                                alt="project-img"
                              />
                              <span className="gallery-icon">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                            <div className="project-item-info">
                              <div className="project-item-info-content">
                                <h3 className="project-item-title">
                                  <a href={`/projectDetail?id=${data.id}`}>
                                    {data.title}
                                  </a>
                                </h3>
                                <p className="project-cat">{data.category}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>
                {/* shuffle end */}
              </div>
              {/* <div className="col-12">
                <div className="general-btn text-center">
                  <a className="btn btn-primary" href="projects.html">
                    View All Projects
                  </a>
                </div>
              </div> */}
            </div>
            {/* Content row end */}
          </div>
          {/*/ Container end */}
        </section>
      </>
    );
  }
}

export default Projects;
