import React, { Component } from "react";
class HowItWorks extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <>
        <>
          <section
            id="ts-service-area"
            className="ts-service-area pb-0 "
            style={{ background: "#E2F0D9" }}
          >
            <div className="container">
              <div className="row text-center">
                <div className="col-12">
                  {/* <h2 className="section-title">{this.props.data.heading1}</h2> */}
                  <h3 className="section-sub-title">How It Works</h3>
                </div>
              </div>
              {/*/ Title row end */}
              <div className="row">
                {/* Col end */}
                <div className="col-md-12 col-sm-12 col-lg-12 text-center">
                  <img
                    loading="lazy"
                    className="img-fluid"
                    style={{ width: "71rem" }}
                    src={require(`./../images/${this.props.data.mainImage}`)}
                    alt="service-avater-image"
                  />
                </div>
                {/* Col end */}

                {/* Col end */}
              </div>
              {/* Content row end */}
            </div>
            {/*/ Container end */}
          </section>
        </>
      </>
    );
  }
}

export default HowItWorks;
