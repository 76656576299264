import React, { Component } from "react";
import db from "./../db.json";
import LocationHeader from "./LocationHeader";
import MainHeader from "./MainHeader";
import Slider from "./Slider";
import AboutUs from "./AboutUs";
import ProjectDetails from "./ProjectDetails";
import WhatWeDo from "./WhatWeDo";
import Projects from "./Projects";
import TestiMonials from "./Testimonials";
import Footer from "./Footer";
import HowItWorks from "./HowItWorks";
import SoftwareWeUse from "./SoftwareWeUse";
import QuoteModal from "./QuoteModal";
class HomePage extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <>
        <LocationHeader data={db.locationHeader} />
        <MainHeader data={db.mainHeader} menus={db.Menus} />
        <Slider data={db.banners} />
        <AboutUs aboutus={db.aboutUs} ourvalues={db.ourvalues} />
        {/* <ProjectDetails data={db.facts} /> */}

        <WhatWeDo data={db.whatWeDo} />
        <HowItWorks data={db.howitworks} />
        <Projects data={db.projects} />
        <TestiMonials data={db.testimonials} />
        <SoftwareWeUse data={db.softwareUse} />
        <Footer
          data={db.footers}
          header={db.locationHeader}
          services={db.services}
        />
        <QuoteModal />
      </>
    );
  }
}

export default HomePage;
